import * as React from "react"
import { ThemeProvider } from "./src/contexts/ThemeContext"
import { checkMobile } from '~/helpers/helpers'


export const onClientEntry = () => {
  const isMobile = checkMobile()
  const el = document.querySelector("html")
  el.setAttribute('hg', localStorage.getItem('homeg') ? localStorage.getItem('homeg') : 'both')
  el.setAttribute('is-m', isMobile.mobile ? 'true' : 'false') 
  isMobile.touches ? el.setAttribute('is-t','true') : el.removeAttribute('is-t')

  if (window.location.pathname === '/') {
    setTimeout(() => {document.querySelectorAll('[g]').forEach(x => x.classList.remove('off'))}, 100)
  }
}


export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {

  // const head = document.querySelector('#header')
  // const head_h = head ? head.getBoundingClientRect().height : 0

  // const plpbar = document.querySelector('[plp-bar]')
  // const plpbar_h = plpbar ? plpbar.getBoundingClientRect().height : 0

  let currentPosition =  getSavedScrollPosition(location)
  let bread = sessionStorage.getItem('bread')

  if (bread && location.pathname.includes('/collections/')) {
    bread = JSON.parse(bread)
    if (location.pathname === `/collections/${bread.handle}/`) currentPosition = [0,bread.scroll]
  }

  if (window.location.hash) {
    currentPosition = [0,document.querySelector(window.location.hash).offsetTop]
  }


  // console.log(currentPosition)
  // if (currentPosition[1] !== 0)currentPosition[1] = currentPosition[1] + 1239.5
  // console.log(currentPosition)
  // const queriedPosition = getSavedScrollPosition({ pathname: `/random` })

  setTimeout(()=>{window.scrollTo(...(currentPosition || [0, 0]))}, 50)


  return false
}

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)